import { IssuesService } from '@/api/api.service'
import {
  ISSUES_GET_ISSUES,
  ISSUES_GET_ISSUE,
  ISSUES_GET_CHANGELOG
} from '@/store/actions.type'
import { format } from '@/helpers'
import {
  ISSUES_GET_START,
  ISSUES_GET_END,
  ISSUES_SET_ISSUES,
  ISSUES_SET_CHANGELOG,
  ISSUES_SET_SELECTED_ISSUE,
  ISSUES_REMOVE_SELECTED_ISSUE,
  ISSUES_SET_ISSUE,
  ISSUES_REMOVE_ISSUE,
  ISSUES_SET_SELECTED_DATE,
  ISSUES_SET_SELECTED_ISSUE_TYPES,
  ISSUES_SET_SELECTED_GROUPS,
  ISSUES_SET_SELECTED_USERS,
  ISSUES_SET_SELECTED_ISSUE_RESULT,
  ISSUES_SET_IS_DATE_FILTERED
} from '@/store/mutations.type'
import AppConfig from '@/constants/app-config'

const initialState = {
  issues: [],
  issue: {},
  selectedIsssue: {},
  selectedGroupds: [],
  selectedUsers: [],
  changelog: [],
  isSelected: false,
  isLoading: true,
  selectedDate: new Date(),
  selectedIssueTypes: [],
  selectedIssueResult: 'all',
  isDateFiltered: false
}

export const state = Object.assign({}, initialState)

const actions = {
  async [ISSUES_GET_ISSUE]({ commit }, issueId) {
    commit(ISSUES_GET_START)
    const { data } = await IssuesService.getIssue(issueId)
    commit(ISSUES_GET_END)
    commit(ISSUES_SET_ISSUE, data)
  },
  async [ISSUES_GET_ISSUES]({ commit, getters }) {
    // FILTER BY DATE
    var params = {}
    if (getters.getIsDateFiltered) {
      params = {
        date: format(getters.getIssuesSelectedDate, 'yyyy-MM-dd'),
        issueType: getters.getIssuesSelectedIssueTypes,
        groups: getters.getselectedGroupds,
        users: getters.getSelectedUsers
      }
    } else {
      params = {
        issueType: getters.getIssuesSelectedIssueTypes,
        groups: getters.getselectedGroupds,
        users: getters.getSelectedUsers
      }
    }
    // FILTER BY TEST RESULT
    var filteredData = []
    const { data } = await IssuesService.getIssues(params)

    if (getters.getIssuesSelectedIssueResult === 'all') {
      filteredData = data
    } else {
      data.forEach(issue => {
        const testField = issue.fields.find(field => field.name === AppConfig.testResultFieldName)
        if (testField !== undefined) {
          if (testField.value === getters.getIssuesSelectedIssueResult) {
            filteredData.push(issue)
          }
        } else if (getters.getIssuesSelectedIssueResult === null) {
          filteredData.push(issue)
        }
      })
    }

    commit(ISSUES_GET_END)
    commit(ISSUES_SET_ISSUES, filteredData)
  },
  async [ISSUES_GET_CHANGELOG]({ commit }, issueId) {
    commit(ISSUES_GET_START)
    const { data } = await IssuesService.getChangelog(issueId)
    commit(ISSUES_GET_END)
    commit(ISSUES_SET_CHANGELOG, data)
  }
}

const mutations = {
  [ISSUES_GET_START](state) {
    state.isLoading = true
  },
  [ISSUES_GET_END](state) {
    state.isLoading = false
  },
  [ISSUES_SET_ISSUE](state, issue) {
    issue.position = {
      lat: issue.latitude,
      lng: issue.longitude
    }
    state.issue = issue
    state.isLoading = false
  },
  [ISSUES_SET_ISSUES](state, issues) {
    const issuesWithPosition = issues.map(elem => {
      elem.position = {
        lat: elem.center.geometry.coordinates[1],
        lng: elem.center.geometry.coordinates[0]
      }
      return elem
    })
    state.issues = issuesWithPosition
    state.isLoading = false
  },
  [ISSUES_SET_SELECTED_ISSUE](state, issue) {
    state.selectedIsssue = issue
    state.isSelected = true
  },
  [ISSUES_REMOVE_SELECTED_ISSUE](state) {
    state.selectedIsssue = null
    state.isSelected = false
  },
  [ISSUES_REMOVE_ISSUE](state) {
    state.issue = {}
  },
  [ISSUES_SET_SELECTED_DATE](state, date) {
    state.selectedDate = date
  },
  [ISSUES_SET_SELECTED_ISSUE_TYPES](state, issueTypes) {
    state.selectedIssueTypes = issueTypes
  },
  [ISSUES_SET_SELECTED_GROUPS](state, groups) {
    state.selectedGroupds = groups
  },
  [ISSUES_SET_SELECTED_USERS](state, users) {
    state.selectedUsers = users
  },
  [ISSUES_SET_CHANGELOG](state, changelog) {
    state.changelog = changelog
  },
  [ISSUES_SET_SELECTED_ISSUE_RESULT](state, issueResult) {
    state.selectedIssueResult = issueResult
  },
  [ISSUES_SET_IS_DATE_FILTERED](state, isEnabled) {
    state.isDateFiltered = isEnabled
  }
}

const getters = {
  getIssuesIssues: state => {
    const sorted = [...state.issues].sort(function(a, b) {
      return new Date(b.updatedAt) - new Date(a.updatedAt)
    })
    return sorted
  },
  getIssuesIssuesMap: state => {
    const locations = [...state.issues].map((item) => {
      return {
        id: item.id

      }
    })
    return locations
  },
  getIssuesIssue: state => {
    return state.issue
  },
  getIssuesIsLoading: state => {
    return state.isLoading
  },
  getSelectedIssue: state => {
    return state.selectedIsssue
  },
  getIsIssueSelected: state => {
    return state.isSelected
  },
  getIsDateFiltered: state => {
    return state.isDateFiltered
  },
  getIssuesSelectedDate() {
    return state.selectedDate
  },
  getIssuesSelectedIssueTypes() {
    return state.selectedIssueTypes
  },
  getIssuesSelectedIssueResult() {
    return state.selectedIssueResult
  },
  getIssuesChangelog() {
    return state.changelog
  },
  getselectedGroupds() {
    return state.selectedGroupds
  },
  getSelectedUsers() {
    return state.selectedUsers
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
