/**
 * App Config File
 */
export default {
  appLogo: '/img/theme/app_icn_oc.png', // App Logo,
  darkLogo: '/img/theme/app_icn_oc.png', // dark logo
  brand: 'Observatorio Ciudadano', // Brand Name
  copyrightText: 'Pixzelle © 2020 All Rights Reserved.', // Copyright Text
  // apiUrl: 'http://localhost:3000/', // https://conmarcelosi.globalcybersec.com
  apiUrl: 'https://observatoriocd.com/api/', // https://manolito-dev.globalcybersec.com/api/
  googleMapsKey: 'AIzaSyCQOh9oThS-mTlQMh9aLsYkzpaJ2Bp-fiA',
  urlIcon: '/img/themes/marker.png',
  testResultFieldName: 'Terminado'
}
