// application.js
export const APPLICATION_SET_SETTINGS = 'APPLICATION:SET_SETTINGS'

// fields.js
export const FIELDS_SET_FIELD = 'FIELDS:SET_FIELD'
export const FIELDS_GET_START = 'FIELDS:GET_START'
export const FIELDS_GET_END = 'FIELDS:GET_END'

// files.js
export const FILES_SET_FILE = 'FILES:SET_FILE'

// forms.js
export const FORMS_SET_FORMS = 'FORMS:SET_FORMS'
export const FORMS_SET_FORM = 'FORMS:SET_FORM'
export const FORMS_GET_START = 'FORMS:GET_START'
export const FORMS_GET_END = 'FORMS:GET_END'

// issues.js
export const ISSUES_SET_ISSUES = 'ISSUES:SET_ISSUES'
export const ISSUES_SET_ISSUE = 'ISSUES:SET_ISSUE'
export const ISSUES_SET_CHANGELOG = 'ISSUES:SET_CHANGELOG'
export const ISSUES_SET_SELECTED_ISSUE = 'ISSUES:SET_SELECTED_ISSUE'
export const ISSUES_SET_SELECTED_DATE = 'ISSUES:SET_SELECTED_DATE'
export const ISSUES_SET_IS_DATE_FILTERED = 'ISSUES:SET_IS_DATE_FILTERED'
export const ISSUES_SET_SELECTED_ISSUE_TYPES = 'ISSUES:SET_SELECTED_ISSUE_TYPES'
export const ISSUES_SET_SELECTED_ISSUE_RESULT = 'ISSUES:SET_SELECTED_ISSUE_RESULT'
export const ISSUES_REMOVE_SELECTED_ISSUE = 'ISSUES:REMOVE_SELECTED_ISSUE'
export const ISSUES_REMOVE_ISSUE = 'ISSUES_REMOVE_ISSUE'
export const ISSUES_GET_START = 'ISSUES:GET_START'
export const ISSUES_GET_END = 'ISSUES:GET_END'
export const ISSUES_SET_SELECTED_GROUPS = 'ISSUES:SET_SELECTED_GROUPS'
export const ISSUES_SET_SELECTED_USERS = 'ISSUES:SET_SELECTED_USERS'

// issue-types.js
export const ISSUE_TYPES_SET_ISSUE_TYPES = 'ISSUE_TYPES:SET_ISSUE_TYPES'
export const ISSUE_TYPES_SET_ISSUE_TYPE = 'ISSUE_TYPES:SET_ISSUE_TYPE'
export const ISSUE_TYPES_GET_START = 'ISSUE_TYPES:GET_START'
export const ISSUE_TYPES_GET_END = 'ISSUE_TYPES:GET_END'

// issue-status.js
export const ISSUE_STATUS_SET_ISSUE_STATUSES = 'ISSUE_STATUS:SET_ISSUE_STATUSES'
export const ISSUE_STATUS_SET_ISSUE_STATUS = 'ISSUE_STATUS:SET_ISSUE_STATUS'
export const ISSUE_STATUS_STATUS_ISSUE_STATUS_TYPES = 'ISSUE_STATUS:STATUS_ISSUE_STATUS_TYPES'
export const ISSUE_STATUS_GET_START = 'ISSUE_STATUS:GET_START'
export const ISSUE_STATUS_GET_END = 'ISSUE_STATUS:GET_END'

// places.js
export const PLACES_SET_PLACES = 'PLACES:SET_PLACES'
export const PLACES_SET_PLACE = 'PLACES:SET_PLACE'
export const PLACES_SET_SELECTED_PLACE = 'PLACES:SET_SELECTED_PLACE'
export const PLACES_REMOVE_SELECTED_PLACE = 'PLACES:REMOVE_SELECTED_PLACE'
export const PLACES_GET_START = 'PLACES:GET_START'
export const PLACES_GET_END = 'PLACES:GET_END'
export const PLACES_SET_PLACE_TYPES = 'PLACES:SET_PLACE_TYPES'
export const PLACES_SET_SELECTED_PLACE_TYPE = 'PLACES:SET_SELECTED_PLACE_TYPE'
export const PLACES_SET_LOCATION = 'PLACES:SET_LOCATION'
export const PLACES_SET_IS_EDITING_PLACE = 'PLACES:SET_IS_EDITING_PLACE'

// sessions.js
export const SESSIONS_SET_ME = 'SESSIONS:SET_ME'
export const SESSION_SET_AUTH = 'SESSIONS:SET_AUTH'
export const SESSION_PURGE_AUTH = 'SESSIONS:PURGE_AUTH'

// settings.js
export const SETTINGS_TOGGLE_SIDEBAR = 'SETTINGS:TOGGLE_SIDEBAR'

// timelines.js
export const TIMELINES_SET_TIMELINES = 'TIMELINES:SET_TIMELINES'
export const TIMELINES_SET_TIMELINE = 'TIMELINES:SET_TIMELINE'
export const TIMELINES_SET_SELECTED_DATE = 'TIMELINES:SET_SELECTED_DATE'
export const TIMELINES_GET_START = 'TIMELINES:GET_START'
export const TIMELINES_GET_END = 'TIMELINES:GET_END'
export const TIMELINES_REMOVE_TIMELINE = 'TIMELINES:REMOVE_TIMELINE'

// tracking.js
export const TRACKING_SET_TRACKING = 'TRACKING:SET_TRACKING'
export const TRACKING_SET_TRACK = 'TRACKING:SET_TRACK'
export const TRACKING_GET_START = 'TRACKING:GET_START'
export const TRACKING_GET_END = 'TRACKING:GET_END'
export const TRACKING_SET_SELECTED_TRACK = 'TRACKING:SET_SELECTED_TRACK'
export const TRACKING_REMOVE_SELECTED_TRACK = 'TRACKING:REMOVE_SELECTED_TRACK'
export const TRACKING_SET_SELECTED_STATUS = 'TRACKING:SET_SELECTED_STATUS'
export const TRACKING_SET_SEARCH = 'TRACKING:SET_SEARCH'
export const TRACKING_SET_SELECTED_GROUPS = 'TRACKING:SET_SELECTED_GROUPS'

// users.js
export const USERS_SET_USERS = 'USERS:SET_USERS'
export const USERS_SELECT_USER = 'USERS:SELECT_USER'
export const USERS_UPDATE_SESSION = 'USERS:UPDATE_SESSION'

// roles.js
export const ROLES_SET_ROLES = 'ROLES:SET_ROLES'
export const ROLES_SELECT_ROL = 'ROLES:SELECT_ROL'
export const ROLES_SET_MODULES = 'ROLES:SET_MODULES'

// groups.js
export const GROUPS_SET_GROUPS = 'GROUPS:SET_GROUPS'
export const GROUPS_SELECT_GROUP = 'GROUPS:SELECT_GROUP'
export const GROUPS_SELECT_GROUP_WITH_DATA = 'GROUPS:SELECT_GROUP_WITH_DATA'

// fields.js
export const FIELDS_SET_FIELDS = 'FIELDS:SET_FIELDS'
export const FIELDS_SELECT_FIELD = 'FIELDS:SELECT_FIELD'
export const FIELDS_SET_FIELD_OPTIONS = 'FIELDS:SET_FIELD_OPTIONS'
export const FIELDS_SET_FIELD_TYPES = 'FIELDS:SET_FIELD_TYPES'
export const FIELDS_SET_FORMS = 'FIELDS:SET_FORMS'

// workflows.js
export const WORKFLOWS_SET_WORKFLOWS = 'WORKFLOWS:SET_WORKFLOWS'
export const WORKFLOWS_SET_WORKFLOW = 'WORKFLOWS:SET_WORKFLOW'
export const WORKFLOWS_GET_START = 'WORKFLOWS:GET_START'
export const WORKFLOWS_GET_END = 'WORKFLOWS:GET_END'
export const WORKFLOWS_SET_TRANSITIONS = 'WORKFLOWS:SET_TRANSITIONS'
export const WORKFLOWS_SET_WORKFLOW_TRANSITION = 'WORKFLOWS:SET_WORKFLOW_TRANSITION'
