<template>
  <v-navigation-drawer
    :mini-variant.sync="getSettingsCollapseSidebar"
    :value="drawer"
    app
    clipped
    mini-variant-width="70"
    :width="250"
  >
    <v-list dense>
      <template v-if="getSettingsCollapseSidebar">
        <div v-for="item in sidebar" :key="item.to">
          <div v-if="!item.children">
            <v-list-item v-if="getSessionUser.roles && getSessionUser.roles.some(r => r.permissions.some(p => p.module.slug === item.to && p.slug === item.type))" link :to="'/' + item.to">
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item>
          </div>
          <v-menu v-else-if="getSessionUser.roles && getSessionUser.roles.some(r => r.permissions.some(p => p.module.slug.includes(item.to)))" open-on-hover top offset-x link>
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list>
              <div v-for="itemChildren in item.children" :key="itemChildren.title">
                <v-list-item v-if="getSessionUser.roles && getSessionUser.roles.some(r => r.permissions.some(p => p.module.slug === itemChildren.to && p.slug === itemChildren.type))" :to="'/' + itemChildren.to" dense>
                  <v-list-item-content>
                    <v-list-item-title v-text="itemChildren.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <!-- <v-list-item v-for="itemChildren in item.children" :key="itemChildren.title" :to="'/' + itemChildren.to">
                <v-list-item-title>{{ itemChildren.title }}</v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-if="!getSettingsCollapseSidebar">
        <div v-for="item in sidebar" :key="item.to">
          <div v-if="!item.children">
            <v-list-item v-if="getSessionUser.roles && getSessionUser.roles.some(r => r.permissions.some(p => p.module.slug === item.to && p.slug === item.type))" link :to="'/' + item.to">
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item>
          </div>
          <v-list-group v-else-if="getSessionUser.roles && getSessionUser.roles.some(r => r.permissions.some(p => p.module.slug.includes(item.to)))" :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <div v-for="itemChildren in item.children" :key="itemChildren.title">
              <v-list-item v-if="getSessionUser.roles && getSessionUser.roles.some(r => r.permissions.some(p => p.module.slug === itemChildren.to && p.slug === itemChildren.type))" :to="'/' + itemChildren.to" dense>
                <v-list-item-content>
                  <v-list-item-title v-text="itemChildren.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TheSidebar',
  props: {},
  data() {
    return {
      drawer: true,
      sidebar: [
        { title: 'Issues', to: 'issues', icon: 'mdi-clipboard-account', type: 'view' },
        { title: 'Usuarios', to: 'tracking', icon: 'mdi-map-marker-radius', type: 'view' },
        { title: 'Zonas', to: 'places', icon: 'mdi-map-check', type: 'view' },
        {
          title: 'Configuración',
          icon: 'mdi-cog',
          to: 'settings',
          children: [
            { title: 'Organización', to: 'settings/organization', type: 'view' },
            { title: 'Aplicación', to: 'settings/application', type: 'view' },
            { title: 'Usuarios', to: 'settings/users', type: 'view' },
            { title: 'Roles', to: 'settings/roles', type: 'view' },
            { title: 'Grupos', to: 'settings/groups', type: 'view' },
            { title: 'Tipo Incidencias', to: 'settings/issue-types', type: 'view' },
            { title: 'Estatus Incidencias', to: 'settings/issue-status', type: 'view' },
            { title: 'Campos', to: 'settings/fields', type: 'view' },
            { title: 'Formularios', to: 'settings/forms', type: 'view' },
            { title: 'Flujos', to: 'settings/workflows', type: 'view' }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getSettingsCollapseSidebar',
      'getSessionUser'
    ])
  },
  methods: {},
  components: {}
}
</script>
