import { RolesService } from '../../api/api.service'
import {
  ROLES_GET_ROLES,
  ROLES_GET_MODULES,
  ROLES_UPDATE_ROLE,
  ROLES_CREATE_ROLE,
  ROLES_GET_ROLE,
  ROLES_DELETE_ROLE
} from '../actions.type'

import {
  ROLES_SET_ROLES,
  ROLES_SELECT_ROL,
  ROLES_SET_MODULES
} from '../mutations.type'

const initialState = {
  roles: [],
  modules: [],
  selectedRol: {}
}

export const state = Object.assign({}, initialState)

const actions = {
  async [ROLES_GET_ROLES]({ commit }) {
    const { data } = await RolesService.getRoles()
    commit(ROLES_SET_ROLES, data)
  },
  async [ROLES_GET_MODULES]({ commit }) {
    const { data } = await RolesService.getModules()
    commit(ROLES_SET_MODULES, data)
  },
  async [ROLES_GET_ROLE](commit, id) {
    const { data } = await RolesService.getRole(id)
    commit(ROLES_SET_MODULES, data)
  },
  async [ROLES_CREATE_ROLE](commit, rol) {
    await RolesService.createRole(rol)
  },
  async [ROLES_DELETE_ROLE](commit, id) {
    await RolesService.deleteRole(id)
  },
  async [ROLES_UPDATE_ROLE](commit, rol) {
    const response = await RolesService.updateRole(rol)
    if (response.status === 200) {
      // context.commit(USERS_UPDATE_USER, response.data)
    }
  }
}

const mutations = {
  [ROLES_SET_ROLES](state, roles) {
    state.roles = roles
  },
  [ROLES_SELECT_ROL](state, id) {
    var index = state.roles.findIndex(u => u.id === id)
    state.selectedRol = state.roles[index]
  },
  [ROLES_SET_MODULES](state, modules) {
    state.modules = modules
  }
}

const getters = {
  getRoles: state => {
    return state.roles
  },
  getSelectedRol: state => {
    return state.selectedRol
  },
  getModules: state => {
    return state.modules
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
