import { TrackingService } from '@/api/api.service'
import { isEmpty } from '@/helpers'
import { TRACKING_GET_TRACKING, TRACKING_GET_TRACK } from '@/store/actions.type'
import {
  TRACKING_SET_TRACKING,
  TRACKING_SET_TRACK,
  TRACKING_GET_START,
  TRACKING_GET_END,
  TRACKING_SET_SELECTED_STATUS,
  TRACKING_REMOVE_SELECTED_TRACK,
  TRACKING_SET_SEARCH,
  TRACKING_SET_SELECTED_GROUPS
} from '@/store/mutations.type'

const initialState = {
  tracking: [],
  track: null,
  selectedTrack: {},
  isLoading: true,
  selectedStatus: null,
  search: '',
  groups: [],
  statusOptions: [
    {
      name: 'Todos',
      value: null
    },
    {
      name: 'Activos',
      value: true
    },
    {
      name: 'Inactivos',
      value: false
    }
  ]
}

export const state = Object.assign({}, initialState)

const actions = {
  async [TRACKING_GET_TRACKING]({ commit, getters }) {
    commit(TRACKING_GET_START)
    const params = {}
    if (!isEmpty(getters.getTrackingSelectedStatus)) {
      params.isActive = getters.getTrackingSelectedStatus
    }
    params.groups = getters.getGroups
    const { data } = await TrackingService.getTracking(params)
    commit(TRACKING_SET_TRACKING, data)
    commit(TRACKING_GET_END)
  },

  async [TRACKING_GET_TRACK]({ commit, getters }, userId) {
    commit(TRACKING_GET_START)
    const { data } = await TrackingService.getTrack(userId)
    commit(TRACKING_SET_TRACK, data)
    commit(TRACKING_GET_END)
  }
}

const mutations = {
  [TRACKING_SET_SELECTED_GROUPS](state, groups) {
    state.groups = groups
  },
  [TRACKING_GET_START](state) {
    state.isLoading = true
  },
  [TRACKING_GET_END](state) {
    state.isLoading = false
  },
  [TRACKING_SET_TRACKING](state, tracking) {
    // Create the position for the map component
    tracking.map(track => {
      track.position = {
        lat: track.latitude,
        lng: track.longitude
      }
    })
    state.tracking = tracking
  },
  [TRACKING_SET_TRACK](state, track) {
    // Create the position for the map component
    track.position = {
      lat: track.latitude,
      lng: track.longitude
    }
    state.track = track
  },
  [TRACKING_REMOVE_SELECTED_TRACK](state) {
    state.selectedTrack = null
    state.track = null
  },
  [TRACKING_SET_SELECTED_STATUS](state, status) {
    state.selectedStatus = status
  },
  [TRACKING_SET_SEARCH](state, search) {
    state.search = search
  }
}

const getters = {
  getTrackingTracking: state => {
    const sorted = [...state.tracking].sort(function(a, b) {
      return new Date(b.trackedAt) - new Date(a.trackedAt)
    }).filter(t => t.user.displayName.toLowerCase().includes(state.search.toLowerCase()))
    // const sorted = [...state.tracking].sort(function(a, b) {
    //   return new Date(b.trackedAt) - new Date(a.trackedAt)
    // })
    return sorted
  },
  getTrackingIsLoading: state => {
    return state.isLoading
  },
  getTrackingStatusOptions: state => {
    return state.statusOptions
  },
  getTrackingSelectedStatus: state => {
    return state.selectedStatus
  },
  getTrackingTrack: status => {
    return state.track
  },
  getTrackingSearch: status => {
    return state.search
  },
  getGroups() {
    return state.groups
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
