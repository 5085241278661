// application.js
export const APPLICATION_GET_SETTINGS = 'APPLICATION:GET_SETTINGS'
export const APPLICATION_UPDATE_SETTINGS = 'APPLICATION:UPDATE_SETTINGS'

// files.js
export const FILES_UPLOAD_FILE = 'FILES:UPLOAD_FILE'

// forms.js
export const FORMS_CREATE_FORM = 'FORMS:CREATE_FORM'
export const FORMS_UPDATE_FORM = 'FORMS:UPDATE_FORM'
export const FORMS_GET_FORMS = 'FORMS:GET_FORMS'
export const FORMS_GET_FORM = 'FORMS:GET_FORM'

// issues.js
export const ISSUES_GET_ISSUES = 'ISSUES:GET_ISSUES'
export const ISSUES_GET_ISSUE = 'ISSUES:GET_ISSUE'
export const ISSUES_GET_CHANGELOG = 'ISSUES:GET_CHANGELOG'

// issue-types.js
export const ISSUE_TYPES_CREATE_ISSUE_TYPE = 'ISSUE_TYPES:CREATE_ISSUE_TYPE'
export const ISSUE_TYPES_UPDATE_ISSUE_TYPE = 'ISSUE_TYPES:UPDATE_ISSUE_TYPE'
export const ISSUE_TYPES_GET_ISSUE_TYPES = 'ISSUE_TYPES:GET_ISSUE_TYPES'
export const ISSUE_TYPES_GET_ISSUE_TYPE = 'ISSUE_TYPES:GET_ISSUE_TYPE'

// issue-status.js
export const ISSUE_STATUS_CREATE_ISSUE_STATUS =
  'ISSUE_STATUS:CREATE_ISSUE_STATUS'
export const ISSUE_STATUS_UPDATE_ISSUE_STATUS =
  'ISSUE_STATUS:UPDATE_ISSUE_STATUS'
export const ISSUE_STATUS_GET_ISSUE_STATUSES = 'ISSUE_STATUS:GET_ISSUE_STATUSES'
export const ISSUE_STATUS_GET_ISSUE_STATUS = 'ISSUE_STATUS:GET_ISSUE_STATUS'
export const ISSUE_STATUS_GET_ISSUE_STATUS_TYPES =
  'ISSUE_STATUS:GET_ISSUE_STATUS_TYPES'

// places.js
export const PLACES_GET_PLACES = 'PLACES:GET_PLACES'
export const PLACES_GET_PLACE = 'PLACES:GET_PLACE'
export const PLACES_UPDATE_PLACE = 'PLACES:UPDATE_PLACE'
export const PLACES_DELETE_USER = 'PLACES:DELETE_USER'
export const PLACES_ADD_USER = 'PLACES:ADD_USER'
export const PLACES_DELETE_PLACE = 'PLACES:DELETE_PLACE'
export const PLACES_GET_PLACE_TYPES = 'PLACES:GET_PLACE_TYPES'
export const PLACES_CREATE_PLACE = 'PLACES:CREATE_PLACE'

// sessions.js
export const SESSION_GET_ME = 'SESSIONS:GET_ME'
export const SESSION_LOGIN = 'SESSIONS:LOGIN'
export const SESSION_LOGOUT = 'SESSIONS:LOGOUT'
export const SESSION_CHECK_AUTH = 'SESSIONS:CHECK_AUTH'
export const SESSION_VERIFY_TOKEN = 'SESSIONS:VERIFY_TOKEN'

// settings.js
export const SETTINGS_COLLAPSE_SIDEBAR = 'SETTINGS:COLLAPSE_SIDEBAR'
export const SETTINGS_HIDE_SIDEBAR = 'SETTINGS:HIDE_SIDEBAR'

// tracking.js
export const TRACKING_GET_TRACKING = 'TRACKING:GET_TRACKING'
export const TRACKING_GET_TRACK = 'TRACKING:GET_TRACK'

// timelines.js
export const TIMELINES_GET_TIMELINES = 'TIMELINES:GET_TIMELINES'
export const TIMELINES_GET_TIMELINE = 'TIMELINES:GET_TIMELINE'

// users.js
export const USERS_CREATE_USER = 'USERS:CREATE_USER'
export const USERS_REMOVE_USER = 'USERS:REMOVE_USER'
export const USERS_UPDATE_USER = 'USERS:UPDATE_USER'
export const USERS_GET_USERS = 'USERS:GET_USERS'
export const USERS_GET_USER = 'USERS:GET_USER'
export const USERS_BLOCK_USER = 'USERS:BLOCK_USER'
export const USERS_UNBLOCK_USER = 'USERS:UNBLOCK_USER'
export const USERS_SET_PASSWORD = 'USERS:SET_PASSWORD'
export const USERS_RESET_EMAIL = 'USERS:RESET_EMAIL'
export const USERS_HAS_ISSUES = 'USERS:HAS_ISSUES'
export const USERS_REMOVE_SESSION = 'USERSREMOVE_SESSION'

// roles.js
export const ROLES_GET_ROLES = 'ROLES:GET_ROLES'
export const ROLES_GET_ROLE = 'ROLES:GET_ROLE'
export const ROLES_UPDATE_ROLE = 'ROLES:UPDATE_ROLE'
export const ROLES_GET_MODULES = 'ROLES:GET_MODULES'
export const ROLES_CREATE_ROLE = 'ROLES:CREATE_ROLE'
export const ROLES_DELETE_ROLE = 'ROLES:DELETE_ROLE'

// groups.js
export const GROUPS_CREATE_GROUP = 'GROUPS:CREATE_GROUP'
export const GROUPS_REMOVE_GROUP = 'GROUPS:REMOVE_GROUP'
export const GROUPS_UPDATE_GROUP = 'GROUPS:UPDATE_GROUP'
export const GROUPS_GET_GROUPS = 'GROUPS:GET_GROUPS'
export const GROUPS_GET_GROUP = 'GROUPS:GET_GROUP'
export const GROUPS_GET_GROUP_MEMBERS = 'GROUPS:GET_GROUP_MEMBERS'
export const GROUPS_ADD_GROUP_MEMBER = 'GROUPS:ADD_GROUP_MEMBER'
export const GROUPS_REMOVE_GROUP_MEMBER = 'GROUPS:REMOVE_GROUP_MEMBER'

// fields
export const FIELDS_CREATE_FIELD = 'FIELDS:CREATE_FIELD'
export const FIELDS_REMOVE_FIELD = 'FIELDS:REMOVE_FIELD'
export const FIELDS_UPDATE_FIELD = 'FIELDS:UPDATE_FIELD'
export const FIELDS_GET_FIELDS = 'FIELDS:GET_FIELDS'
export const FIELDS_GET_FIELD = 'FIELDS:GET_FIELD'
export const FIELDS_GET_FIELD_OPTIONS = 'FIELDS:GET_FIELD_OPTIONS'
export const FIELDS_GET_FIELD_TYPES = 'FIELDS:FIELDS_GET_FIELD_TYPES'
export const FIELDS_CREATE_FIELD_OPTION = 'FIELDS:CREATE_FIELD_OPTION'
export const FIELDS_REMOVE_FIELD_OPTION = 'FIELDS:REMOVE_FIELD_OPTION'
export const FIELDS_GET_FORMS = 'FIELDS:GET_FORMS'

// workflows.js
export const WORKFLOWS_CREATE_WORKFLOW = 'WORKFLOWS:CREATE_WORKFLOW'
export const WORKFLOWS_UPDATE_WORKFLOW = 'WORKFLOWS:UPDATE_WORKFLOW'
export const WORKFLOWS_GET_WORKFLOWS = 'WORKFLOWS:GET_WORKFLOWS'
export const WORKFLOWS_GET_WORKFLOW = 'WORKFLOWS:GET_WORKFLOW'
export const WORKFLOWS_GET_TRANSITIONS = 'WORKFLOWS:GET_TRANSITIONS'
export const WORKFLOWS_CREATE_TRANSITION = 'WORKFLOWS:CREATE_TRANSITION'
export const WORKFLOWS_UPDATE_TRANSITION = 'WORKFLOWS:UPDATE_TRANSITION'
export const WORKFLOWS_DELETE_TRANSITION = 'WORKFLOWS:DELETE_TRANSITION'
export const WORKFLOWS_GET_WORKFLOW_TRANSITION =
  'WORKFLOWS:GET_WORKFLOW_TRANSITION'
