import Full from '@/containers/full'

const Dashboard = () => import('@/views/dashboard/dashboard')
const Issues = () => import('@/views/issues/issues')
// Places
const Places = () => import('@/views/places/places')
const PlaceNew = () => import('@/views/places/place-new')

// Tracking
const Tracking = () => import('@/views/tracking/tracking')

// Timelines
const Timelines = () => import('@/views/timelines/timelines')

// Settings
const Users = () => import('@/views/settings/users/users')
const UsersCreate = () => import('@/views/settings/users/create')
const UsersImport = () => import('@/views/settings/users/import')
const UsersEdit = () => import('@/views/settings/users/edit')
const UsersProfile = () => import('@/views/settings/users/me')

// - Application
const Application = () => import('@/views/settings/application/application')
// - Issue Types
const IssueTypes = () => import('@/views/settings/issue-types/issue-types')
const IssueTypeNew = () => import('@/views/settings/issue-types/issue-type-new')
const IssueTypeEdit = () =>
  import('@/views/settings/issue-types/issue-type-edit')
// - Issue Status
const IssueStatus = () => import('@/views/settings/issue-status/issue-status')
const IssueStatusNew = () =>
  import('@/views/settings/issue-status/issue-status-new')
const IssueStatusEdit = () =>
  import('@/views/settings/issue-status/issue-status-edit')
// - Workflows
const Workflows = () => import('@/views/settings/workflows/workflows')
const WorkflowNew = () => import('@/views/settings/workflows/workflow-new')
const WorkflowEdit = () => import('@/views/settings/workflows/workflow-edit')
// - Forms
const Forms = () => import('@/views/settings/forms/forms')
const FormNew = () => import('@/views/settings/forms/form-new')
const FormEdit = () => import('@/views/settings/forms/form-edit')
// - Forms
const Organization = () => import('@/views/settings/organization/organization')

// Groups
const Groups = () => import('@/views/groups/groups')
const GroupsCreate = () => import('@/views/groups/create')
const GroupsEdit = () => import('@/views/groups/edit')

// Fields
const Fields = () => import('@/views/fields/fields')
const FieldsCreate = () => import('@/views/fields/create')
const FieldsEdit = () => import('@/views/fields/edit')

// Rols
const Rols = () => import('@/views/settings/rols/rols')
const RolsEdit = () => import('@/views/settings/rols/edit')
const RolsCreate = () => import('@/views/settings/rols/create')

export default {
  path: '/',
  component: Full,
  redirect: '/issues',
  children: [
    {
      path: '/dashboard',
      component: Dashboard,
      meta: {
        auth: true,
        title: 'message.dashboard'
      }
    },
    {
      path: '/issues',
      component: Issues,
      name: 'issues',
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'view'
      }
    },
    {
      path: '/tracking',
      component: Tracking,
      name: 'tracking',
      meta: {
        auth: true,
        title: 'message.places',
        type: 'view'
      }
    },
    {
      path: '/timelines',
      component: Timelines,
      name: 'timelines',
      meta: {
        auth: true,
        title: 'message.places'
      }
    },
    {
      path: '/places',
      component: Places,
      name: 'places',
      meta: {
        auth: true,
        title: 'message.places',
        type: 'view'
      }
    },
    {
      path: 'places/new',
      component: PlaceNew,
      name: 'places-new',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'create'
      }
    },
    {
      path: 'settings/issue-types',
      props: true,
      component: IssueTypes,
      name: 'issue-types',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'view'
      }
    },
    {
      path: 'settings/issue-types/:issueTypeId/edit',
      props: true,
      component: IssueTypeEdit,
      name: 'issue-types-edit',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'edit'
      }
    },
    {
      path: 'settings/issue-types/new',
      props: true,
      component: IssueTypeNew,
      name: 'issue-types-new',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'create'
      }
    },
    {
      path: 'settings/issue-status',
      props: true,
      component: IssueStatus,
      name: 'issue-status',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'view'
      }
    },
    {
      path: 'settings/issue-status/:issueStatusId/edit',
      props: true,
      component: IssueStatusEdit,
      name: 'issue-status-edit',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'edit'
      }
    },
    {
      path: 'settings/issue-status/new',
      props: true,
      component: IssueStatusNew,
      name: 'issue-status-new',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'create'
      }
    },
    {
      path: 'settings/workflows',
      props: true,
      component: Workflows,
      name: 'workflows',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'view'
      }
    },
    {
      path: 'settings/workflows/:workflowId/edit',
      props: true,
      component: WorkflowEdit,
      name: 'workflows-edit',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'edit'
      }
    },
    {
      path: 'settings/workflows/new',
      props: true,
      component: WorkflowNew,
      name: 'workflows-new',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'create'
      }
    },
    {
      path: 'settings/forms',
      props: true,
      component: Forms,
      name: 'forms',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'view'
      }
    },
    {
      path: 'settings/forms/:formId/edit',
      props: true,
      component: FormEdit,
      name: 'forms-edit',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'edit'
      }
    },
    {
      path: 'settings/forms/new',
      props: true,
      component: FormNew,
      name: 'forms-new',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'create'
      }
    },
    {
      path: '/settings/users',
      component: Users,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'view'
      }
    },
    {
      path: '/settings/users/create',
      component: UsersCreate,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'create'
      }
    },
    {
      path: '/settings/users/import',
      component: UsersImport,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'view'
      }
    },
    {
      path: '/settings/users/:id',
      component: UsersEdit,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'edit'
      }
    },
    // Roles
    {
      path: '/settings/roles',
      component: Rols,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'view'
      }
    },
    {
      path: '/settings/roles/create',
      component: RolsCreate,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'create'
      }
    },
    {
      path: '/settings/roles/:id',
      component: RolsEdit,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'edit'
      }
    },
    {
      path: 'my-profile',
      component: UsersProfile,
      meta: {
        auth: true,
        title: 'message.issues'
      }
    },
    {
      path: '/settings/groups',
      component: Groups,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'view'
      }
    },
    {
      path: '/settings/groups/create',
      component: GroupsCreate,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'create'
      }
    },
    {
      path: '/settings/groups/:id',
      component: GroupsEdit,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'edit'
      }
    },
    {
      path: 'settings/organization',
      props: true,
      component: Organization,
      name: 'organization',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'view'
      }
    },
    {
      path: 'settings/application',
      props: true,
      component: Application,
      name: 'application',
      meta: {
        auth: true,
        title: 'message.places - ',
        type: 'view'
      }
    },
    {
      path: '/settings/fields',
      component: Fields,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'view'
      }
    },
    {
      path: '/settings/fields/create',
      component: FieldsCreate,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'create'
      }
    },
    {
      path: '/settings/fields/:id/edit',
      component: FieldsEdit,
      meta: {
        auth: true,
        title: 'message.issues',
        type: 'edit'
      }
    }
  ]
}
